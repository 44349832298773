import SplitType from 'split-type';
import gsap from 'gsap';

export function swingingText() {
  const splitH3 = new SplitType('.case-title', {
    types: 'words, chars',
    tagName: 'span',
  });
  const caseLinks = document.querySelectorAll('.theme1 .case-study');
  caseLinks.forEach((link) => {
    const firstChars = link.querySelectorAll('.case-title.title-1 .char');
    const secondChars = link.querySelectorAll('.case-title.title-2 .char');
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.5, stagger: 0.025, ease: 'power3.inOut' },
    });
    tl.to(firstChars, {
      y: '-100%',
      rotationX: 90,
      // rotationY: 6,
      opacity: 0,
    });
    tl.from(
      secondChars,
      {
        y: '100%',
        rotationX: -90,
        // rotationY: -6,
        opacity: 0,
      },
      0
    );

    link.addEventListener('mouseenter', () => tl.restart());
    link.addEventListener('mouseleave', () => tl.reverse());
  });
}

//
export function scramlingTextHandler() {
  // let $word = document.querySelectorAll('.text-scramble');
  const textScrambleAll = document.querySelectorAll('.text-scramble');
  textScrambleAll.forEach((textScramble) => {
    const splitWord = textScramble.textContent.split('');
    let newWord = '';
    splitWord.forEach((a) => {
      newWord += `<span className="letter">${a}</span>`;
    });
    textScramble.innerHTML = newWord;
  });

  function animateLetter(obj) {
    const alpha = [
      '▨',
      '☺',
      '☀',
      '♪',
      '♫',
      '♥',
      '✨',
      '#',
      '*',
      'A',
      'G',
      'T',
      'H',
      'Y',
      'Z',
      'X',
      'W',
      'O',
      'K',
      'Q',
      'S',
    ];
    // var $letter = $(obj);
    // $letter.addClass('changing'); //change color of letter
    const original = obj.textContent; // get original letter for use later
    // console.log(original);

    // loop through random letters
    let i = 0;
    const letterInterval = setInterval(() => {
      // get random letter
      const randomLetter = alpha[Math.floor(Math.random() * alpha.length)];
      // console.log(randomLetter);
      obj.innerHTML = randomLetter;
      if (i === 5) {
        // if letter has changed 5 times then stop
        clearInterval(letterInterval);
        obj.innerHTML = original; // set back to original letter
        // console.log('clear');
      }
      ++i;
    }, 60);
  }

  function animateWord(obj) {
    const letters = obj.childNodes;
    // console.log(letters);
    letters.forEach((i, j) => {
      // trigger animation for each letter in word
      setTimeout(() => {
        animateLetter(i);
      }, 60 * j); // small delay for each letter
    });
  }

  // Hover-anim on t3 case-study
  if (document.querySelector('.case-study.scramble-contain')) {
    const cases = document.querySelectorAll('.case-study.scramble-contain');
    cases.forEach((c) => {
      const h3 = c.querySelector('h3');
      c.addEventListener('mouseenter', () => {
        if (h3.classList.contains('scrambling')) {
          return;
        }
        h3.classList.add('scrambling');
        animateWord(h3);
        setTimeout(() => {
          h3.classList.remove('scrambling');
        }, 1000);
      });
    });
  }
}
