import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PageHead from '../components/PageHead';
import Layout from '../components/Layout';
import CaseContent1 from './CaseContent1';
import CaseContent2 from './CaseContent2';
import CaseContent3 from './CaseContent3';
import './case-styles.scss';

export function Head({ location, data }) {
  const caseData = data.sanityCaseStudy;

  return (
    <PageHead
      title={caseData.seo.title && caseData.seo.title}
      description={caseData.seo.description && caseData.seo.description}
      image={caseData.seo.ogImage && caseData.seo.ogImage.asset.url}
      location={location}
    />
  );
}

export default function CaseStudyPage({ data, location }) {
  const caseData = data.sanityCaseStudy;
  const unshuffulledCases = data.allSanityCaseStudy.nodes;
  const allCases = unshuffulledCases
    .sort(() => Math.random() - 0.5)
    .slice(0, 2);

  const [activeTheme, setActiveTheme] = useState(() => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('themeRekh');
    }
    return null;
  });
  const [dummyState, setDummyState] = useState(false); // to trigger loco refresh when theme is changed

  return (
    <Layout
      location={location}
      theme={activeTheme}
      setTheme={setActiveTheme}
      dummyState={dummyState}
      setDummyState={setDummyState}
      navScroll
    >
      {activeTheme === 'theme1' && (
        <CaseContent1 data={caseData} allCases={allCases} />
      )}
      {activeTheme === 'theme2' && (
        <CaseContent2 data={caseData} allCases={allCases} />
      )}
      {activeTheme === 'theme3' && (
        <CaseContent3 data={caseData} allCases={allCases} />
      )}
    </Layout>
  );
}

export const query = graphql`
  query GetSingleCase($slug: String) {
    sanityCaseStudy(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      field
      subhead
      thumbnail {
        asset {
          gatsbyImageData
        }
      }
      role
      websiteLink {
        name
        link
      }
      collab {
        title
        name
        link
      }
      caseBody1 {
        _rawBody1
      }
      caseBody2 {
        images {
          ... on SanityFigure {
            alt
            asset {
              gatsbyImageData
            }
          }
          ... on SanityVideoFile {
            alt
            thumb {
              asset {
                gatsbyImageData
              }
            }
            video {
              asset {
                url
              }
            }
          }
        }
      }
      caseBody3 {
        title3
        _rawBody3
      }
      caseBody4 {
        images {
          ... on SanityFigure {
            alt
            asset {
              gatsbyImageData
            }
          }
          ... on SanityVideoFile {
            alt
            thumb {
              asset {
                gatsbyImageData
              }
            }
            video {
              asset {
                url
              }
            }
          }
        }
      }
      caseBody5 {
        title5
        footerPic {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
    allSanityCaseStudy(filter: { slug: { current: { ne: $slug } } }) {
      nodes {
        title
        slug {
          current
        }
        field
        thumbnail {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
