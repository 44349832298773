import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import PortableText from '@sanity/block-content-to-react';
import { swingingText } from '../uitilities/caseHovers';
import Swipe from '../images/ico-swipe.svg';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h2') {
        return <h2 className="split-lines">{children}</h2>;
      }
      return <p className="para big split-lines">{children}</p>;
    },
  },
};

export default function CaseContent1({ data, allCases }) {
  const [swiperActive, setSwiperActive] = useState(false);

  useEffect(() => {
    swingingText();
  });

  return (
    <>
      <header>
        <div className="hero t1-case-hero">
          <div className="wrapper col-grid">
            <div className="empty" />

            <div className="hero-content">
              <h1 className="split-n-wrap">{data.title}</h1>
              <h2 className="subhead split-n-wrap">{data.subhead}</h2>

              <div className="case-info">
                <div className="info-left">
                  <div className="info-data">
                    <span>My Role</span>
                  </div>
                  <p className="role info-data">{data.role}</p>
                  {data.collab && (
                    <>
                      <div className="info-data collab-title">
                        <span>{data.collab.title}</span>
                      </div>
                      <p className="collab info-data">
                        {data.collab.link ? (
                          <a
                            href={data.collab.link}
                            target="_blank"
                            className="link"
                            rel="noreferrer"
                          >
                            {data.collab.name}
                          </a>
                        ) : (
                          data.collab.name
                        )}
                      </p>
                    </>
                  )}
                </div>
                {data.websiteLink && (
                  <a
                    href={data.websiteLink.link}
                    target="_blank"
                    className="link info-data"
                    rel="noreferrer"
                  >
                    {data.websiteLink.name}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="hero-img">
          <div className="wrapper col-grid">
            <div className="empty" />

            <div className="image-contain item-img-wrap">
              <div className="image-item item-img">
                <GatsbyImage
                  image={data.thumbnail.asset.gatsbyImageData}
                  alt={data.title}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="t1-case-intro">
        <div className="wrapper col-grid">
          <div className="empty" />

          <div className="intro-content">
            <PortableText
              blocks={data.caseBody1._rawBody1}
              serializers={serializers}
            />
          </div>
        </div>
      </section>

      <section className="t1-case-two-pics">
        <div className="wrapper">
          {data.caseBody2.images.map((pic, i) => (
            <div className={`pic pic-${i + 1} item-img-wrap`} key={i}>
              <div className="pic-content item-img">
                {pic.asset && (
                  <GatsbyImage
                    image={pic.asset.gatsbyImageData}
                    alt={pic.alt}
                  />
                )}
                {pic.video && (
                  <video
                    src={pic.video.asset.url}
                    poster={pic.thumb.asset.url}
                    type="video/mp4"
                    muted
                    autoPlay
                    loop
                    playsInline
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="t1-case-details">
        <div className="wrapper col-grid">
          <div className="empty" />

          <div className="details-text">
            <h3 className="split-lines">{data.caseBody3.title3}</h3>
            <PortableText
              blocks={data.caseBody3._rawBody3}
              serializers={serializers}
            />
          </div>
        </div>
      </section>

      <section className="t1-case-gallery">
        <div className="wrapper">
          <p className="swipe-ico">
            Swipe
            <img src={Swipe} alt="swipe" />
          </p>

          <Swiper
            className={`gallery-pics ${swiperActive ? 'active' : ''}`}
            slidesPerView="auto"
            spaceBetween={20}
            grabCursor
            onTouchStart={() => setSwiperActive(true)}
            onTouchEnd={() => setSwiperActive(false)}
          >
            {data.caseBody4.images.map((pic, i) => (
              <SwiperSlide className={`pic pic-${i + 1}`} key={i}>
                <div className="pic-content">
                  {pic.asset && (
                    <GatsbyImage
                      image={pic.asset.gatsbyImageData}
                      alt={pic.alt}
                    />
                  )}
                  {pic.video && (
                    <video
                      src={pic.video.asset.url}
                      poster={pic.thumb.asset.url}
                      type="video/mp4"
                      muted
                      autoPlay
                      loop
                      playsInline
                      disablePictureInPicture
                      controlsList="nodownload noplaybackrate"
                    />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section className="t1-case-footer">
        <div className="wrapper col-grid">
          <div className="empty" />

          <div className="footer-pic-contain item-img-wrap">
            <div className="footer-pic item-img">
              <GatsbyImage
                image={data.caseBody5.footerPic.asset.gatsbyImageData}
                alt={data.caseBody5.footerPic.alt}
              />
            </div>
          </div>
        </div>

        <div className="wrapper col-grid">
          <div className="empty" />
          <div className="footer-text">
            <h3 className="split-lines">{data.caseBody5.title5}</h3>
          </div>
        </div>
      </section>

      <section className="t1-next-cases">
        <div className="wrapper col-grid">
          <div className="sec-title">
            <div className="title">
              <span>Up next</span>
            </div>
          </div>

          <div className="all-cases">
            {allCases.map((work, i) => (
              <Link
                to={`/${work.slug.current}`}
                className="case-study element-reveal"
                key={i}
              >
                <div className="thumbnail item-img-wrap">
                  <div className="thumbnail-content item-img">
                    <GatsbyImage
                      image={work.thumbnail.asset.gatsbyImageData}
                      alt={work.title}
                    />
                  </div>
                </div>
                <div className="info">
                  <h3>
                    <div className="case-title title-1">{work.title}</div>
                    <div className="case-title title-2">{work.title}</div>
                  </h3>
                  <span className="field">{work.field}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
