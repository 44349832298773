import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import PortableText from '@sanity/block-content-to-react';
import TagCarousel from '../components/theme3/TagCarousel';
import { scramlingTextHandler } from '../uitilities/caseHovers';
import Swipe from '../images/ico-swipe.svg';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h2') {
        return <h2 className="split-lines">{children}</h2>;
      }
      return <p className="para big split-lines">{children}</p>;
    },
  },
};

export default function CaseContent3({ data, allCases }) {
  const [swiperActive, setSwiperActive] = useState(false);

  useEffect(() => {
    scramlingTextHandler();
  });

  return (
    <>
      <header>
        <div className="hero t3-case-hero">
          <div className="heading">
            <div className="wrapper">
              <div className="text-content">
                <h1 className="show-up">{data.title}</h1>
                <p className="subhead split-n-wrap">{data.subhead}</p>
              </div>

              {data.websiteLink && (
                <div className="cta">
                  <a
                    href={data.websiteLink.link}
                    target="_blank"
                    rel="noreferrer"
                    className="button outline data-info"
                  >
                    Visit website
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="hero-img">
          <div className="wrapper wrapper-2">
            <div className="image-contain item-img-wrap">
              <div className="image-item item-img">
                <GatsbyImage
                  image={data.thumbnail.asset.gatsbyImageData}
                  alt={data.title}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="t3-case-intro">
        <div className="wrapper">
          <div className="sidebar">
            <div className="sidebar-left">
              <span>Role</span>
              <p className="role split-lines">{data.role}</p>
            </div>

            <div className="sidebar-right">
              {data.collab && (
                <>
                  <span className="collab-title">{data.collab.title}</span>
                  <p className="collab">
                    {data.collab.link ? (
                      <a
                        href={data.collab.link}
                        target="_blank"
                        className="link"
                        rel="noreferrer"
                      >
                        {data.collab.name}
                      </a>
                    ) : (
                      data.collab.name
                    )}
                  </p>
                </>
              )}
              {data.websiteLink && (
                <a
                  href={data.websiteLink.link}
                  target="_blank"
                  className="link website-link"
                  rel="noreferrer"
                >
                  {data.websiteLink.name}
                </a>
              )}
            </div>
          </div>

          <div className="intro-content">
            <PortableText
              blocks={data.caseBody1._rawBody1}
              serializers={serializers}
            />
          </div>
        </div>
      </section>

      <section className="t3-case-two-pics">
        <div className="wrapper wrapper-2">
          {data.caseBody2.images.map((pic, i) => (
            <div className={`pic pic-${i + 1} item-img-wrap`} key={i}>
              <div className="pic-content item-img">
                {pic.asset && (
                  <GatsbyImage
                    image={pic.asset.gatsbyImageData}
                    alt={pic.alt}
                  />
                )}
                {pic.video && (
                  <video
                    src={pic.video.asset.url}
                    poster={pic.thumb.asset.url}
                    type="video/mp4"
                    muted
                    autoPlay
                    loop
                    playsInline
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="t3-case-details">
        <div className="wrapper">
          <div className="details-title">
            <h3 className="split-lines">{data.caseBody3.title3}</h3>
          </div>
          <div className="details-para">
            <PortableText
              blocks={data.caseBody3._rawBody3}
              serializers={serializers}
            />
          </div>
        </div>
      </section>

      <section className="t3-case-gallery">
        <div className="wrapper wrapper-2">
          <p className="swipe-ico">
            Swipe
            <img src={Swipe} alt="swipe" />
          </p>
          <Swiper
            className={`gallery-pics ${swiperActive ? 'active' : ''}`}
            slidesPerView="auto"
            spaceBetween={20}
            grabCursor
            onTouchStart={() => setSwiperActive(true)}
            onTouchEnd={() => setSwiperActive(false)}
          >
            {data.caseBody4.images.map((pic, i) => (
              <SwiperSlide className={`pic pic-${i + 1}`} key={i}>
                <div className="pic-content">
                  {pic.asset && (
                    <GatsbyImage
                      image={pic.asset.gatsbyImageData}
                      alt={pic.alt}
                    />
                  )}
                  {pic.video && (
                    <video
                      src={pic.video.asset.url}
                      poster={pic.thumb.asset.url}
                      type="video/mp4"
                      muted
                      autoPlay
                      loop
                      playsInline
                      disablePictureInPicture
                      controlsList="nodownload noplaybackrate"
                    />
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section className="t3-case-footer">
        <div className="wrapper">
          <h3 className="split-lines">{data.caseBody5.title5}</h3>
        </div>
        <div className="wrapper wrapper-2">
          <div className="footer-pic-contain item-img-wrap">
            <div className="footer-pic item-img">
              <GatsbyImage
                image={data.caseBody5.footerPic.asset.gatsbyImageData}
                alt={data.caseBody5.footerPic.alt}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="t3-next-cases">
        <div className="wrapper">
          <TagCarousel title="Up next" />

          <div className="all-cases">
            {allCases.map((work, i) => (
              <Link
                to={`/${work.slug.current}`}
                className="scramble-contain case-study element-reveal"
                key={i}
              >
                <div className="thumb item-img-wrap">
                  <div className="thumb-content item-img">
                    <GatsbyImage
                      image={work.thumbnail.asset.gatsbyImageData}
                      alt={work.title}
                    />
                  </div>
                </div>
                <h3 className="text-scramble">{work.title}</h3>
                <p className="info para small">{work.field}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
