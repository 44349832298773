import React, { useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import PortableText from '@sanity/block-content-to-react';
import Swipe from '../images/ico-swipe.svg';

const serializers = {
  types: {
    block: ({ children, node }) => {
      if (node.style === 'h2') {
        return <h2 className="small split-lines">{children}</h2>;
      }
      return <p className="para big split-lines">{children}</p>;
    },
  },
};

export default function CaseContent2({ data, allCases }) {
  const [swiperActive, setSwiperActive] = useState(false);

  return (
    <>
      <header>
        <div className="hero t2-case-hero">
          <div className="wrapper">
            <div className="heading">
              <h1 className="split-n-wrap">{data.title}</h1>
              <h3 className="subhead split-n-wrap">{data.subhead}</h3>
            </div>

            <div className="project-info">
              <div className="info-data">
                <span>Role</span>
              </div>
              <p className="role info-data">{data.role}</p>

              {data.collab && (
                <>
                  <div className="collab-title info-data">
                    <span>{data.collab.title}</span>
                  </div>
                  <p className="collab info-data">
                    {data.collab.link ? (
                      <a
                        href={data.collab.link}
                        target="_blank"
                        className="link"
                        rel="noreferrer"
                      >
                        {data.collab.name}
                      </a>
                    ) : (
                      data.collab.name
                    )}
                  </p>
                </>
              )}

              {data.websiteLink && (
                <a
                  href={data.websiteLink.link}
                  target="_blank"
                  className="link website-link info-data"
                  rel="noreferrer"
                >
                  {data.websiteLink.name}
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="hero-img">
          <div className="image-contain item-img-wrap">
            <div className="image-item item-img">
              <GatsbyImage
                image={data.thumbnail.asset.gatsbyImageData}
                alt={data.title}
              />
            </div>
          </div>
        </div>
      </header>

      <section className="t2-case-intro">
        <div className="wrapper">
          <div className="intro-content">
            <PortableText
              blocks={data.caseBody1._rawBody1}
              serializers={serializers}
            />
          </div>
        </div>
      </section>

      <section className="t2-case-two-pics">
        {data.caseBody2.images.map((pic, i) => (
          <div className={`pic pic-${i + 1} item-img-wrap`} key={i}>
            <div className="pic-content item-img">
              {pic.asset && (
                <GatsbyImage image={pic.asset.gatsbyImageData} alt={pic.alt} />
              )}
              {pic.video && (
                <video
                  src={pic.video.asset.url}
                  poster={pic.thumb.asset.url}
                  type="video/mp4"
                  muted
                  autoPlay
                  loop
                  playsInline
                  disablePictureInPicture
                  controlsList="nodownload noplaybackrate"
                />
              )}
            </div>
          </div>
        ))}
      </section>

      <section className="t2-case-details">
        <div className="wrapper">
          <div className="details-title">
            <h3 className="big split-lines">{data.caseBody3.title3}</h3>
          </div>
          <div className="details-para">
            <PortableText
              blocks={data.caseBody3._rawBody3}
              serializers={serializers}
            />
          </div>
        </div>
      </section>

      <section className="t2-case-gallery">
        <p className="swipe-ico">
          Swipe
          <img src={Swipe} alt="swipe" />
        </p>
        <Swiper
          className={`gallery-pics ${swiperActive ? 'active' : ''}`}
          slidesPerView="auto"
          spaceBetween={20}
          grabCursor
          onTouchStart={() => setSwiperActive(true)}
          onTouchEnd={() => setSwiperActive(false)}
        >
          {data.caseBody4.images.map((pic, i) => (
            <SwiperSlide className={`pic pic-${i + 1}`} key={i}>
              <div className="pic-content">
                {pic.asset && (
                  <GatsbyImage
                    image={pic.asset.gatsbyImageData}
                    alt={pic.alt}
                  />
                )}
                {pic.video && (
                  <video
                    src={pic.video.asset.url}
                    poster={pic.thumb.asset.url}
                    type="video/mp4"
                    muted
                    autoPlay
                    loop
                    playsInline
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate"
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <section className="t2-case-footer">
        <div className="wrapper">
          <h3 className="big split-lines">{data.caseBody5.title5}</h3>
        </div>

        <div className="footer-pic-contain item-img-wrap">
          <div className="footer-pic item-img">
            <GatsbyImage
              image={data.caseBody5.footerPic.asset.gatsbyImageData}
              alt={data.caseBody5.footerPic.alt}
            />
          </div>
        </div>
      </section>

      <section className="t2-next-cases">
        <div className="wrapper">
          <div className="floating-tag green ball-reveal">
            <div className="tag-content">
              <p>Up next</p>
            </div>
          </div>

          <div className="all-cases">
            {allCases.map((work, i) => (
              <Link
                to={`/${work.slug.current}`}
                className="case-study element-reveal"
                key={i}
              >
                <div className="thumb item-img-wrap">
                  <div className="thumb-content item-img">
                    <GatsbyImage
                      image={work.thumbnail.asset.gatsbyImageData}
                      alt={work.title}
                    />
                  </div>
                </div>
                <h3>{work.title}</h3>
                <p className="info para small">{work.field}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
